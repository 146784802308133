// COMPONENTS
import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'layout/Layout'
import SEO from 'layout/SEO'
import Title from 'layout/Title'
import Body from 'layout/Body'
import PostFooter from 'BlogPost/PostFooter'

// Blog post page template
const BlogPost = ({ data }) => {
  const post = data.markdownRemark
  const { html, frontmatter } = post
  const { title, subtitle, author, date, image } = frontmatter
  //const markdownRemark = data.markdownRemark

  return (
    <Layout>
      <SEO title={title} image={image} />
      <Title
        title={title}
        subtitle={subtitle}
        author={author}
        date={date}
        image={image}
      />
      <Body html={html} />
      <PostFooter inverted />
    </Layout>
  )
}

export const postQuery = graphql`
  query BlogPost($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
        subtitle
        path
        date
        author
        image
        layout
        tags
      }
    }
  }
`

export default BlogPost
